import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Header from "./Navigation/Header";
import Footer from "./Navigation/Footer";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Team from "./Pages/Ourteam/Team";
import Industries from "./Pages/Industries/Industries";
import JoinUs from "./Pages/Join/Join";
import Solution from "./Pages/Solutions/Solutions";
import Loading from "./Loading/Loading";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a data fetch or initial load
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after 2 seconds
    }, 2000); // Adjust the duration as needed

    return () => clearTimeout(timer); // Cleanup timeout on component unmount
  }, []);

  return (
    <div>
      <Header />
      <main>
        {loading ? (
          <Loading />
        ) : (
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            {/* <Route path="/team" element={<Team />} /> */}
            <Route path="/industries/:card?" element={<Industries />} />
            <Route path="/join" element={<JoinUs />} />
            <Route path="/solutions/:card?" element={<Solution />} />
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default App;
